import { EWHOS_SLIDER_BACKGROUND_COLOR } from "@/enums/component.enum";
import { useDragging } from "@/hooks/useDragging.hook";
import classNames from "classnames";
import React, { CSSProperties, forwardRef, useImperativeHandle, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import styled from "styled-components";

type TCustomSettings = {
  columnGap?: number;
  rowGap?: number;
  arrowWrapWidth?: CSSProperties["width"];
  sliderArrowOffsetY?: number;
  backgroundColor?: EWHOS_SLIDER_BACKGROUND_COLOR;
  showArrowOnHover?: boolean;
};

type TProps<T extends { id: number }> = {
  settings?: Omit<Settings, "slidesToScroll" | "slidesToShow"> & TCustomSettings; // slidesToScroll, slidesToShow 는 사용x
  sliderItems: T[];
  render: (sliderItem: T, sliderItemIndex: number) => JSX.Element;
  className?: string;
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  sliderArrowType?: number;
  materials?: boolean;
};

const WhosSlider = <T extends { id: number }>(
  {
    settings,
    sliderItems,
    render,
    className,
    width = "100%",
    height = "unset",
    sliderArrowType = 1,
    materials = false,
  }: TProps<T>,
  ref: React.Ref<Slider | null>
) => {
  const defaultSettings: Required<
    Pick<
      Settings,
      | "speed"
      | "slidesPerRow"
      | "rows"
      | "initialSlide"
      | "dots"
      | "arrows"
      | "autoplay"
      | "pauseOnHover"
      | "infinite"
      | "prevArrow"
      | "nextArrow"
    >
  > &
    Omit<Required<TCustomSettings>, "arrowWrapWidth"> &
    Pick<TCustomSettings, "arrowWrapWidth"> = {
    speed: 700,
    slidesPerRow: 1, // 열을 추가하고 싶을 때 사용
    rows: 1, // 행을 추가하고 싶을 때 사용
    initialSlide: 0,
    dots: false,
    arrows: true,
    autoplay: false,
    pauseOnHover: true,
    infinite: true,
    prevArrow: <SliderPrevArrow sliderArrowType={sliderArrowType} />,
    nextArrow: <SliderNextArrow sliderArrowType={sliderArrowType} />,
    // customSettings
    columnGap: 0,
    rowGap: 0,
    arrowWrapWidth: undefined,
    sliderArrowOffsetY: 0,
    backgroundColor: EWHOS_SLIDER_BACKGROUND_COLOR.WHITE,
    showArrowOnHover: false,
  };

  // slidesToScroll, slidesToShow 설정 기본값으로 override
  const immutableSettings: Required<Pick<Settings, "slidesToScroll" | "slidesToShow">> = {
    slidesToScroll: 1,
    slidesToShow: 1,
  };

  const finalSettings = Object.assign(defaultSettings, settings, immutableSettings);

  const { isDragging, onMouseDown, onMouseMove, onMouseUp, onMouseLeave } = useDragging();
  const sliderRef = useRef<Slider>(null);
  const [activeSlide, setActiveSlide] = useState(finalSettings.initialSlide);
  const totalItems = sliderItems.length;
  const isFirstPage = activeSlide === 0;
  const isLastPage =
    activeSlide === Math.ceil(totalItems / (finalSettings.slidesPerRow * finalSettings.rows)) - 1;
  const hideSliderPrevArrow = isFirstPage && !defaultSettings.infinite;
  const hideSliderNextArrow = isLastPage && !defaultSettings.infinite;

  useImperativeHandle(ref, () => sliderRef.current);

  if (sliderItems.length === 0) return null;

  const isMultipleSliderItems = sliderItems.length >= 2;

  return (
    <Layout
      className={classNames("whos-slider", className)}
      $columnGap={finalSettings.columnGap}
      $rowGap={finalSettings.rowGap}
      $slidesPerRow={finalSettings.slidesPerRow}
      $hideSliderPrevArrow={hideSliderPrevArrow}
      $hideSliderNextArrow={hideSliderNextArrow}
      $width={width}
      $height={height}
      $arrowWrapWidth={finalSettings.arrowWrapWidth}
      $sliderArrowOffsetY={finalSettings.sliderArrowOffsetY}
      $backgroundColor={finalSettings.backgroundColor}
      $showArrowOnHover={finalSettings.showArrowOnHover}
      $materials={materials}
    >
      <Slider
        {...finalSettings}
        ref={sliderRef}
        beforeChange={(curSlide: number, nextSlide: number) => {
          finalSettings?.beforeChange?.(curSlide, nextSlide);
          setActiveSlide(nextSlide);
        }}
      >
        {sliderItems.map((sliderItem, sliderItemIndex) => (
          <div
            key={sliderItemIndex}
            className="slider-item"
            onMouseDown={(e) => {
              onMouseDown(e);
              isMultipleSliderItems && e.preventDefault();
            }}
            onMouseMove={isMultipleSliderItems ? onMouseMove : undefined}
            onMouseUp={isMultipleSliderItems ? onMouseUp : undefined}
            onMouseLeave={isMultipleSliderItems ? onMouseLeave : undefined}
          >
            {React.cloneElement(render(sliderItem, sliderItemIndex), {
              onClick: (e: React.MouseEvent) => {
                if (isDragging) return e.preventDefault();

                render(sliderItem, sliderItemIndex).props.onClick?.(e);
              },
              style: {
                ...(isDragging && { cursor: "grabbing" }),
                ...render(sliderItem, sliderItemIndex).props.style,
              },
            })}
          </div>
        ))}
      </Slider>
    </Layout>
  );
};

export default forwardRef(WhosSlider) as <T extends { id: number }>(
  props: TProps<T> & { ref?: React.Ref<Slider | null> }
) => ReturnType<typeof WhosSlider>;

const Layout = styled.div<{
  $columnGap: number;
  $rowGap: number;
  $slidesPerRow: number;
  $hideSliderPrevArrow: boolean;
  $hideSliderNextArrow: boolean;
  $width: number | string;
  $height: number | string;
  $arrowWrapWidth: CSSProperties["width"];
  $sliderArrowOffsetY: number;
  $backgroundColor: EWHOS_SLIDER_BACKGROUND_COLOR;
  $showArrowOnHover: boolean;
  $materials: boolean;
}>`
  width: ${({ $width }) => (typeof $width === "number" ? $width + "px" : $width)};
  height: ${({ $height }) => (typeof $height === "number" ? $height + "px" : $height)};
  .slick-slider {
    width: 100%;
    height: 100%;

    &:not(:hover) {
      .slider-prev-arrow,
      .slider-next-arrow {
        opacity: ${({ $showArrowOnHover }) => $showArrowOnHover && 0};
      }
    }

    .slider-prev-arrow,
    .slider-next-arrow {
      position: absolute;
      top: ${({ $sliderArrowOffsetY }) => `calc(50% + ${$sliderArrowOffsetY}px)`};
      z-index: 1;
      pointer-events: auto;
    }

    .slider-prev-arrow {
      display: ${({ $hideSliderPrevArrow }) => ($hideSliderPrevArrow ? "none" : "flex")};
      left: ${({ $arrowWrapWidth }) =>
        typeof $arrowWrapWidth === "number"
          ? `calc(50% - ${$arrowWrapWidth}px / 2)`
          : `calc(50% - ${$arrowWrapWidth ? $arrowWrapWidth : "100%"} / 2)`};
      transform: ${({ $arrowWrapWidth }) =>
        $arrowWrapWidth ? "translateY(-50%)" : "translate(-50%, -50%)"};
    }

    .slider-next-arrow {
      display: ${({ $hideSliderNextArrow }) => ($hideSliderNextArrow ? "none" : "flex")};
      right: ${({ $arrowWrapWidth }) =>
        typeof $arrowWrapWidth === "number"
          ? `calc(50% - ${$arrowWrapWidth}px / 2)`
          : `calc(50% - ${$arrowWrapWidth ? $arrowWrapWidth : "100%"} / 2)`};
      transform: ${({ $arrowWrapWidth }) =>
        $arrowWrapWidth ? "translateY(-50%)" : "translate(+50%, -50%)"};
    }

    .slick-list {
      width: 100%;
      height: 100%;

      .slick-track {
        height: 100%;

        .slick-slide {
          display: flex;
          flex-direction: column;
          height: 100%;
          row-gap: ${({ $rowGap }) => $rowGap + "px"};

          > div {
            display: grid;
            grid-template-columns: ${({ $slidesPerRow }) => `repeat(${$slidesPerRow}, 1fr)`};
            height: 100%;
            column-gap: ${({ $columnGap }) => $columnGap + "px"};

            .slider-item {
              position: relative;
              padding-bottom: ${({ $materials }) => $materials && "100%"};
              min-width: 100%;
              height: 100%;
              background: ${({ $backgroundColor }) => $backgroundColor};
              aspect-ratio: ${({ $materials }) => $materials && 1};
              img {
                object-fit: cover;
              }
            }
          }
        }
      }
    }

    .slick-dots {
      display: flex !important;
      align-items: center;
      justify-content: center;
      bottom: 16px;
      pointer-events: auto;

      @media screen and (max-width: 767px) {
        bottom: 4px;
      }

      li {
        display: flex;
        margin: 0;
        width: unset;
        height: unset;

        @media screen and (max-width: 767px) {
          margin: 0 -2px;
        }

        button::before {
          opacity: 1;
          color: #dcdcdc;
          font-size: 10px;
          transition: 0.7s;

          @media screen and (max-width: 767px) {
            font-size: 6px;
          }
        }

        &.slick-active button::before {
          color: #f35935;
        }
      }
    }
  }
`;

const SliderPrevArrow = ({ onClick, sliderArrowType }: any) => {
  switch (sliderArrowType) {
    case 1:
      return (
        <button className="slider-prev-arrow" onClick={onClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
          >
            <g filter="url(#filter0_d_10155_179357)">
              <circle cx="24" cy="22" r="20" fill="white" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.4242 13.6648L19.3143 21.0989C18.8939 21.4842 18.8939 22.1469 19.3143 22.5323L27.4242 29.9664L28.3628 28.9426L20.5879 21.8156L28.3628 14.6886L27.4242 13.6648Z"
                fill="#888888"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_10155_179357"
                x="0"
                y="0"
                width="48"
                height="48"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_10155_179357"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_10155_179357"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </button>
      );
    case 2:
      return (
        <button className="slider-prev-arrow" onClick={onClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="79"
            viewBox="0 0 46 79"
            fill="none"
          >
            <path d="M45 0.517578L1 39.5L45 78.4825" stroke="white" strokeLinecap="round" />
          </svg>
        </button>
      );
    case 3:
      return (
        <button className="slider-prev-arrow" onClick={onClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="59"
            height="103"
            viewBox="0 0 59 103"
            fill="none"
          >
            <path d="M58 1L1 51.5L58 102" stroke="white" strokeWidth="2.5" strokeLinecap="round" />
          </svg>
        </button>
      );
  }
};

const SliderNextArrow = ({ onClick, sliderArrowType }: any) => {
  switch (sliderArrowType) {
    case 1:
      return (
        <button className="slider-next-arrow" onClick={onClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
          >
            <g filter="url(#filter0_d_10155_179358)">
              <circle cx="24" cy="22" r="20" fill="white" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.5728 30.3328L28.6828 22.8986C29.1031 22.5133 29.1031 21.8506 28.6828 21.4653L20.5728 14.0312L19.6343 15.055L27.4092 22.182L19.6343 29.3089L20.5728 30.3328Z"
                fill="#888888"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_10155_179358"
                x="0"
                y="0"
                width="48"
                height="48"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_10155_179358"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_10155_179358"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </button>
      );
    case 2:
      return (
        <button className="slider-next-arrow" onClick={onClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="79"
            viewBox="0 0 46 79"
            fill="none"
          >
            <path
              d="M0.999999 0.517578L45 39.5L0.999999 78.4825"
              stroke="white"
              strokeLinecap="round"
            />
          </svg>
        </button>
      );
    case 3:
      return (
        <button className="slider-next-arrow" onClick={onClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="59"
            height="103"
            viewBox="0 0 59 103"
            fill="none"
          >
            <path d="M1 1L58 51.5L1 102" stroke="white" strokeWidth="2.5" strokeLinecap="round" />
          </svg>
        </button>
      );
  }
};
