import { TEnterEventHackleKeys, THackleEvent } from "@/types/common.hackle-type";
import { generateAndTrackHackleEvent } from "@/utils/hackle.util";
import { useEffect, useRef } from "react";
import { useUserInfo } from "./useUserInfo.hook";

export const useTrackHackleEnterEvent = <K extends TEnterEventHackleKeys>(
  key: K,
  properties: THackleEvent[K],
  dependencyArray?: any[],
  disabled?: boolean
) => {
  dependencyArray = dependencyArray ?? [];
  const debounceRef = useRef<NodeJS.Timeout | null>(null);
  const { hackleLoginUserProperties } = useUserInfo();

  useEffect(() => {
    if (disabled) return;

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      generateAndTrackHackleEvent(key, {
        ...hackleLoginUserProperties,
        ...properties,
      });
    }, 2000);
  }, [hackleLoginUserProperties, ...dependencyArray, disabled]);
};
