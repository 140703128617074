import { useRef, useState } from "react";

export const useDragging = () => {
  const [isDragging, setIsDragging] = useState(false);
  const startX = useRef<number | null>(null);

  const onMouseDown = (e: React.MouseEvent) => {
    startX.current = e.pageX;
    setIsDragging(false);
  };

  const onMouseMove = (e: React.MouseEvent) => {
    if (startX.current && Math.abs(e.pageX - startX.current) > 10) {
      setIsDragging(true);
    }
  };

  const onMouseUp = () => {
    setTimeout(() => {
      setIsDragging(false);
    }, 10);
    startX.current = null;
  };

  const onMouseLeave = () => {
    setIsDragging(false);
    startX.current = null;
  };

  return {
    isDragging,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onMouseLeave,
  };
};
